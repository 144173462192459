import React from "react"

import Layout from "../components/layout"
import FAQ from "../components/faq"
import SEO from "../components/seo"

const FaqPage = () => (
  <Layout>
    <SEO title="FAQ" />
    <FAQ />
  </Layout>
)

export default FaqPage
