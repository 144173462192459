import React from "react"
import { Link } from "gatsby"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class FAQ extends React.Component {
  render() {
    return (
      <div className="main">
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="cover-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <h2 className="question">Q: Who is invited?</h2>
                    <div className="answer">
                      At this time we are limiting invitations to the person(s)
                      listed on your invitation. Ideally, we'd love to celebrate
                      this day with the loved ones of our loved ones!
                      <br />
                      <br />
                      Please check in with one of us if you'd like to bring a
                      guest and we'll let you know if we can accomodate them.
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h2 className="question">Q: What about my kids?</h2>
                  <div className="answer">
                    Everyone deserves a kid free night but we're happy to be
                    joined by little ones under the age of one.
                    <br />
                    <br />
                    Please reach out if you would like referals for child care
                    on the night of the wedding as we may offer to coordinate if
                    there are multiple families with this need.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="cover-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <h2 className="question">Q: What about gifts?</h2>
                    <div className="answer">
                      We've set up a registry, check it out at the link below.
                      <br />
                      <br />
                      <div style={{ textAlign: "center", marginTop: "-18px" }}>
                        <Link
                          className="button-link__roundstyle"
                          to="https://www.blueprintregistry.com/registry/RXG-via-TWA"
                          target="_blank"
                        >
                          REGISTRY
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h2 className="question">Q: What About Covid?</h2>
                  <div className="answer">
                    Key to New York requires that any person attending an indoor
                    event be fully vaccinated.
                    <br />
                    <br />
                    Be prepared to show proof of vaccination upon arrival.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-wrapper">
          <div className="border-bottom">
            <div className="cover-grid">
              <div className="column column-border-wrapper">
                <div className="column-border">
                  <div className="column-cell">
                    <h2 className="question">Q: Extending your stay?</h2>
                    <div className="answer">
                      We recommend folks stay at the hotel on the night of the
                      party, it's about one hour from Manhattan or Brooklyn via
                      public transit.
                      <br />
                      <br />
                      If you have the opportunity to extend your stay you'll
                      probably want to find a hotel or short term rental closer
                      to the sights you'd like to see.
                      {/* Click "Our NYC" for a list of our favorite places to spend time!  */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="column-cell">
                  <h2 className="question">Q: Still have questions?</h2>
                  <div className="answer">
                    Get in touch with us!
                    <br />
                    <br />
                    <div style={{ textAlign: "center", marginTop: "18px" }}>
                      <Link className="button-link" to="/contact">
                        CONTACT
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
